<script setup lang="ts">
const config = useRuntimeConfig();
if (config.public.storeId == 'professional-store') {
  useAuthGuardRedirection();
}

// Navigation for default theme
const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData('mainNavigation', () => {
  return loadNavigationElements({ depth: 2 });
});
provide('swNavigation-main-navigation', data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: 'footer-navigation',
});
const { data: footerData } = useAsyncData('mainFooterNavigation', () => {
  return loadFooterNavigationElements({ depth: 2 });
});
provide('swNavigation-footer-navigation', footerData);
</script>

<template>
  <div>
    <LayoutHeader
      :without-icon="true"
      :header-headline="$t('header.success.headline')"
    />
    <LayoutNotifications />
    <div class="max-w-screen-xl mx-auto">
      <slot />
    </div>
    <LayoutBreadcrumbs
      :breadcrumb-title="$t('header.success.headline')"
      breadcrumb-link="success"
      :show-arrow="true"
    />
    <LayoutFooter />
  </div>
</template>
